<!-- =========================================================================================
    File Name: designationForm.vue
    Description: Work Type
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- ADD Work Type FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Project">
          <form>
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Project Name</h6>
                <vs-input
                  type="text"
                  name="Project Name"
                  v-model="form.contract_project_name"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Type of Project</h6>
                <vs-input
                  type="text"
                  name="Type of Project"
                  v-model="form.project_type"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of Project')"
                    >{{ errors.first("Type of Project") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Type of Show</h6>
                <vs-input
                  type="text"
                  name="Type of Show"
                  v-model="form.show_type"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of Show')"
                    >{{ errors.first("Type of Show") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Directors</h6>
                <vs-input
                  type="text"
                  name="Directors"
                  v-model="form.directors"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Directors')"
                    >{{ errors.first("Directors") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Writers</h6>
                <vs-input
                  type="text"
                  name="Writers"
                  v-model="form.writers"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Writers')"
                    >{{ errors.first("Writers") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Lead Cast</h6>
                <vs-input
                  type="text"
                  name="Lead Cast"
                  v-model="form.lead_cast"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Lead Cast')"
                    >{{ errors.first("Lead Cast") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Internal CD</h6>
                <vs-input
                  type="text"
                  name="Internal CD"
                  v-model="form.internal_cd"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Internal CD')"
                    >{{ errors.first("Internal CD") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>No. of Episodes</h6>
                <vs-input
                  type="text"
                  name="No. of episodes"
                  v-model="form.episodes"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('No. of episodes')"
                    >{{ errors.first("No. of episodes") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Shoot Days</h6>
                <vs-input
                  type="text"
                  name="Shoot Days"
                  v-model="form.shoot_days"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Shoot Days')"
                    >{{ errors.first("Shoot Days") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import ContractService from "@/services/ContractService.js";
import CompanyService from "@/services/companyService.js";
import axios from "axios";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { BASEURL } from "@/config/index.js";
export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      excelurl: BASEURL(),
      submitStatus: true,
      switch1: true,
      form: {
        contract_project_name: "",
        project_type: "",
        show_type: "",
        directors: "",
        writers: "",
        lead_cast: "",
        internal_cd: "",
        episodes: null,
        shoot_days: null,
        contract_project_isActive: 1,
      },
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let payload = {
            ...this.form,
          };
          ContractService.addContractProject(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();

              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
                setTimeout(() => {
                  eventBus.$emit("refreshContractProjectTable", data);
                }, 1);
              } else {
                console.log("data :", data);
                this.$vs.notify({
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
              this.$vs.loading.close();
              this.$vs.notify({
                text: error.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_isactive/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    // this.getCompanyList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
