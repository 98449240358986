<!-- =========================================================================================
     File Name: designationList.vue
    Description: Designation
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- DESIGNATION LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Project List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>-->
              <!-- <vx-tooltip text="Export Excel">
                <a
                  :href="excelurl+`/v1/designation/excel/download?token=${temp_token}&search=${searchQuery}`"
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>-->
              <!--
              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="excelurl+`/v1/designation/excel/download?token=${temp_token}&archive=1&search=${searchQuery}`"
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>-->
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="contractProjects"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import ContractService from "@/services/ContractService.js";

import companyService from "@/services/companyService.js";
import { BASEURL } from "@/config/index.js";
import SearchService from "@/services/searchService.js";
import projectStatus from "./projectStatus.vue";
import editProject from "./editProject.vue";
import projectArchive from "./projectArchive.vue";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL(),
      archive: this.listType,
      User: "Select User",
      contractProjects: [],
      totalPages: 1,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      columnDefs: [],
      contacts: contacts,
    };
  },
  beforeMount() {
    eventBus.$on("refreshContractProjectTable", (e) => {
      this.getContractProjectList(this.currentPage);
    });
    this.columnDefs = [
      // {
      //   headerName: "Sr.No.",
      //   field: "firstname",
      //   width: 150,
      //   filter: false,
      //   checkboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   headerCheckboxSelection: true
      // },
      {
        headerName: "Project Name",
        field: "contract_project_name",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Type of Project",
        field: "project_type",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Type of Show",
        field: "show_type",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Directors",
        field: "directors",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Writers",
        field: "writers",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Lead Cast",
        field: "lead_cast",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Internal CD",
        field: "internal_cd",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "No. of Episodes",
        field: "episodes",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Shoot Days",
        field: "shoot_days",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },

      {
        headerName: "Status",
        field: "contract_project_isActive",
        filter: false,
        width: 150,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: projectStatus,
      },
      {
        headerName: "Edit",
        field: "contract_project_id",
        filter: false,
        width: 150,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: editProject,
      },

      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        sortable: false,
        width: this.archive ? 160 : 150,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: projectArchive,
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getContractProjectList(val);
          return val;
        } else return 1;
        // this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    updateSearchQuery(val) {
      SearchService.getAllSerchResultV2(val, "contractProjectType", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.contractProjects = data.data;
          } else {
            this.contractProjects = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getContractProjectList: function (currentPage) {
      this.$vs.loading();
      let filters = {
        page: currentPage,
      };
      if (this.archive == "archive") {
        if (this.contractProjects.length == 1) {
          this.contractProjects = [];
        }
        filters["archive"] = true;
      }
      ContractService.getContractProjects(filters)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.contractProjects = data.data;
            this.totalPages = data.pagination.last_page;
          } else {
            this.$vs.notify({
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    // if (this.checkPermission(["designation", "GET"])) {
    //   this.getContractProjectList(this.currentPage);
    // }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
