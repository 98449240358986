<template>
  <div>
    <b-form-checkbox
      value="1"
      unchecked-value="2"
      v-model="form.contract_project_isActive"
      switch
      class="table-status-icon"
      @change.native="popupActive = true"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.contract_project_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Project Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox()"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button
                color="danger"
                type="filled"
                @click.native="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import ContractService from "@/services/ContractService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        contract_project_isActive: "1",
      },
      copy_contract_project_isActive: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_contract_project_isActive = this.form.contract_project_isActive;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.contract_project_id
    );
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function () {
      this.$vs.loading();
      let payload = {
        ...this.form,
      };

      ContractService.addContractProject(payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function () {
      this.popupActive = false;
      this.form.contract_project_isActive = this.copy_contract_project_isActive;
    },
  },
});
</script>
